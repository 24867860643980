@import 'src/styles/variables';
@import 'src/styles/mixins';

.Container {
  width: 100%;

  background-color: $white;

  &_Info {
    padding: 1.4rem 1.8rem;

    text-align: left;

    background-color: $grey18;
  }

  &_Delimiter {
    height: 1px;
    border: none;

    background-color: $grey3;
  }

  &_Item {
    @include flex-align-center;
    width: 100%;
    padding: 1.8rem 2.2rem;

    &_Icon {
      width: 1.2rem;

      &_Wide {
        width: 1.6rem;
      }
    }

    & > span {
      margin-left: 1.2rem;
    }
  }

  &_PrivacyPolicy {
    margin-top: 1.4rem;
    margin-bottom: 1.4rem;

    font-size: 1.2rem;

    a {
      text-decoration: underline;
    }
  }
}
