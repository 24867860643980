@import 'src/styles/variables';
@import 'src/styles/mixins';

@mixin PrimaryBase {
  border: 1px solid $blue-primary;

  background-color: $blue-primary;

  @include FocusAndActiveBackground($blue-primary-3);
}

@mixin DisableMouse {
  cursor: not-allowed;

  pointer-events: none;
}

.Default {
  @include PrimaryBase;
  @include FocusShadow;
  height: 4rem;
  padding: 0 2rem;

  border-radius: 5px;

  &_Disabled {
    @include FocusAndActiveBackground($grey7);
    @include DisableMouse;
    border-color: $grey7;

    background-color: $grey7;
  }

  &_isBig {
    min-height: 5.6rem;
    padding: 1.8rem 3.2rem;
  }
}

.Add_More {
  height: 2.8rem;
  padding-right: 1.4rem;
  border-radius: 5px;

  background-color: $grey13;

  &_Disabled {
    @include DisableMouse;
    background-color: $grey6;
    filter: grayscale(1);
  }

  &_isBig {
    height: 4.4rem;
    padding-left: 0.9rem;
  }

  &_WithSamePadding {
    padding-left: 1.4rem;
  }

  &:hover {
    background-color: $grey11;
  }

  &:active {
    background-color: $blue-secondary-6;
  }

  &:focus {
    background-color: $grey13;
    box-shadow: 0 0 6px 0 $blue-secondary-5;
  }
}

.Only_Icon {
  @include PrimaryBase;
  @include FocusShadow;
  display: flex;
  height: 4rem;
  padding: 0.8rem 1.25rem;

  border-radius: 5px;
}

.WithPrimaryHover {
  &:hover {
    @include PrimaryBase;

    > * {
      filter: invert(95%) sepia(5%) saturate(0%) hue-rotate(33deg)
        brightness(105%) contrast(106%);
    }

    > span {
      color: $white;

      filter: unset;
    }
  }
}

.IsSmall {
  height: auto;
  min-height: 2.8rem;
  padding: 0.2rem 0.8rem;
}

.IsWide {
  min-width: 12rem;
}

.PrimaryPressed {
  background-color: $blue-primary-2;
}

.Outlined {
  background-color: $white;

  @include FocusAndActiveBackground($grey13);
}

.OutlinedDisabled {
  @include DisableMouse;
  @include FocusAndActiveBackground($grey12);

  border-color: $grey8;
}

.WithGreyBorder {
  border: 1px solid $grey8;
}

.Danger {
  border-color: $red4;

  background-color: $red4;

  @include FocusAndActiveBackground($red4);
}

.FullWidth {
  width: 100%;
}

.Icon {
  @include flex-wrap;
  @include flex-centered;
}

.IconLeft {
  padding-right: 1em;
}

.IconLeft_WithoutPadding {
  padding-right: 0;
}

.IconRight {
  padding-left: 1em;
}

.IconRight_WithoutPadding {
  padding-left: 0;
}

.Icon_Wrapper {
  @include flex-wrap;
}

@include forScreen($mobile-small-width, $desktop-width) {
  .Default_isBig {
    padding: 0 2rem;
  }
}
